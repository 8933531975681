<template>
  <mobile-screen :header="true" screen-class="country-screen gray-bg icon-app1">
    <template v-slot:header>
      <top-header-menu-wrapper
        menu-class="countries-header icon-hea1"
        :helpOnline="
          Boolean(
            helpOnline[helpSlug] &&
              helpOnline[helpSlug].name &&
              helpOnline[helpSlug].content
          )
        "
        :helpOnlineRoute="{
          name: helpOnlineLinkName,
          params: $route.params
        }"
      >
        <template v-slot:left>
          <router-link :to="{ name: backLinkName, params: $route.params }">
            <icon icon="#cx-hea1-arrow-left" />
          </router-link>
        </template>
        <div class="component-title">
          {{ displayLabelName("countries", "countries", "countries") }}
        </div>
      </top-header-menu-wrapper>
      <section class="search-wrapper coutries-search">
        <div class="search-field">
          <input
            type="text"
            @keyup="searchQuery = $event.target.value"
            :placeholder="displayLabelName('countries', 'countries', 'search')"
          />
          <div class="icon search">
            <icon icon="#cx-app1-search-16x16" width="16" height="16" />
          </div>
        </div>
      </section>
    </template>
    <ul
      class="clebex-item-section"
      v-if="listOfCountries && listOfCountries.length"
    >
      <li
        class="clebex-item-section-item full-right-underline"
        v-for="country in listOfCountries"
        :key="country.id"
      >
        <button
          class="clebex-item-content-wrapper"
          @click="selectCountry(country)"
        >
          <span class="label">{{ country.name }}</span>
          <span class="follow-up-icons">
            <span class="follow-up-icon-item">
              <icon
                :icon="[
                  selectedCountry && country.id == selectedCountry.id
                    ? '#cx-app1-checkmark'
                    : '#cx-app1-arrow-right-12x12'
                ]"
                :width="[
                  selectedCountry && country.id == selectedCountry.id
                    ? '20'
                    : '12'
                ]"
                :height="[
                  selectedCountry && country.id == selectedCountry.id
                    ? '20'
                    : '12'
                ]"
              ></icon>
            </span>
          </span>
        </button>
      </li>
    </ul>
  </mobile-screen>
  <router-view />
</template>

<script>
import { mapActions, mapState } from "vuex";
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import helpOnlineMixin from "@/services/mixins/help_online/help-online-mixin";
import countriesMixin from "@/services/mixins/countries/countries-mixin";

export default {
  name: "CountriesSelection",
  mixins: [helpOnlineMixin, countriesMixin],
  data() {
    return {
      country: null,
      selectCountryId: 0,
      processing: false,
      show: false,
      searchQuery: "",
      helpSlug: "levels-countries"
    };
  },
  computed: {
    ...mapState("country", ["countries", "selectedCountry"]),
    listOfCountries() {
      if (this.countries && this.countries.data && this.countries.data.length) {
        let countries = this.countries.data.slice(0);

        const query = this.searchQuery;

        if (query && query.length > 1) {
          countries = countries.filter(item =>
            item.name.toLowerCase().includes(query.toLowerCase())
          );
        }

        return countries.sort((a, b) => {
          if (this.selectedCountry && b.id == this.selectedCountry.id) {
            return 1;
          }
          if (this.selectedCountry && a.id == this.selectedCountry.id) {
            return -1;
          }
          if (a.name < b.name) {
            return -1;
          }
          if (b.name < a.name) {
            return 1;
          }
          return 0;
        });
      }
      return null;
    }
  },
  methods: {
    ...mapActions("country", ["getCountries", "setSelectedCountry"]),
    selectCountry(country) {
      this.selectCountryId = country.id;
      this.country = country;
      this.confirmSelection();
    },
    goBack() {
      this.$router.push({
        name: this.backLinkName,
        params: this.$route.params
      });
    },
    confirmSelection() {
      this.setSelectedCountry(this.country);
      this.goBack();
    }
  },
  components: {
    MobileScreen,
    TopHeaderMenuWrapper
  },
  props: {
    backLinkName: {
      type: String,
      required: true
    },
    helpOnlineLinkName: {
      type: String
    }
  }
};
</script>
